<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t("common.Search") }}</label>
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('common.Search')"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="$store.state.locale.locale == 'ar' ? columnsArabic : columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <div slot="emptystate">
        {{
          isLoading ? $t("dashboard.Loading") : $t("dashboard.RecordNotFound")
        }}
      </div>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'fullName'" class="text-nowrap">
          <!-- <b-avatar :src="props.row.avatar" class="mx-1" /> -->
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <span
          v-else-if="props.column.field === 'createdAt'"
          class="text-nowrap"
        >
          <!-- <b-avatar :src="props.row.avatar" class="mx-1" /> -->
          <span class="text-nowrap">{{ formatDate(props.row.createdAt) }}</span>
        </span>
        <span
          v-else-if="props.column.field === 'typeofOwner'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{
            typeOwner(props.row.typeofOwner)
          }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'project-edit',
                  params: { id: props.row._id },
                }"
              >
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>{{ $t("common.Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="{
                  name: 'profile-details',
                  params: { id: props.row._id },
                }"
                target="_blank"
              >
                <feather-icon icon="TvIcon" class="mr-50" />
                <span>{{ $t("common.Show") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> {{ $t("common.Showing_to") }} </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap">
              {{ $t("common.of") }} {{ props.total }} {{ $t("common.entries") }}
            </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import moment from "moment";

import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BLink,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      isLoading: false,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Owner type",
          field: "typeofOwner",
        },
        {
          label: "Created at ",
          field: "createdAt",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      columnsArabic: [
        {
          label: "اسم المشروع",
          field: "name",
        },
        {
          label: "المالك",
          field: "typeofOwner",
        },
        {
          label: "أنشئت في",
          field: "createdAt",
        },
        {
          label: "الإجراء",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },

    ...mapGetters({
      user: "auth/user",
    }),
  },
  created() {
    this.isLoading = true;

    axios.get(`userProfiles/${this.user._id}/project`).then((response) => {
      this.rows = response.data.data;
      this.isLoading = false;
    });
  },

  methods: {
    statusProfile(data) {
      if (data == "1") return "Complete";
      else return "Incomplete";
    },
    formatDate(value) {
      return moment(String(value)).format("YYYY-MM-DD");
    },
    typeOwner(data) {
      console.log(data);
      if (data === "individual")
        return this.$store.state.locale.locale == "ar" ? "فرد" : "individual";
      else return this.$store.state.locale.locale == "ar" ? "مجموعة" : "group";
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-good-table.scss";
</style>
